import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

function Footer() {
  return (
    <footer className="-z-10 w-full bg-[#FFDD00] py-6 px-4">
      <div className="mx-auto flex max-w-6xl flex-col items-center justify-center gap-y-2">
        <Link className="mr-6" to="/">
          <StaticImage height={40} src="../assets/logo.png" alt="Logo" />
        </Link>
        <p className="text-sm font-bold">
          © 2022 JELLOWO. ALL RIGHTS RESERVED.
        </p>
        <div className="flex flex-row gap-4">
          <Link className="text-sm font-bold" to="/privacy-policy">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
