import React, { useState, useLayoutEffect } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faDiscord,
  faTwitter,
  faInstagram,
  faMedium,
} from "@fortawesome/free-brands-svg-icons"
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons"
import useSound from "use-sound"
import tickSfx from "../assets/sounds/tick.mp3"
import popSfx from "../assets/sounds/pop.mp3"
import logo from "../assets/logo.png"
import opensea from "../assets/icon/opensea.png"

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return size
}

function Navbar(props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [width] = useWindowSize()
  const [play, { stop }] = useSound(popSfx)
  const [playTick] = useSound(tickSfx)

  return (
    <div
      className={`z-50 bg-white py-3 px-7 font-poppins shadow-lg ${
        props.sticky ? "sticky top-0 left-0" : null
      }`}
    >
      <div className="container mx-auto flex flex-col items-center justify-between gap-4 md:items-start lg:flex-row lg:items-center">
        <div className="flex w-full flex-row items-center justify-between lg:block lg:w-auto">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="mx-auto h-[40px] object-contain"
            />
          </Link>
          <button
            className="flex h-4 w-4 items-center justify-center p-4 lg:hidden"
            onClick={() => {
              props.volume ? playTick() : stop()
              setMenuOpen(!menuOpen)
            }}
          >
            {menuOpen ? (
              <FontAwesomeIcon icon={faTimes} className="text-xl" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="text-xl" />
            )}
          </button>
        </div>

        <div
          className={`w-full max-w-sm flex-col justify-end gap-4 lg:flex lg:max-w-none lg:flex-row ${
            width < 1024 && menuOpen ? "flex" : "hidden"
          }`}
        >
          {props.path === "/" ? (
            <div className="flex w-full flex-col items-center gap-4 lg:flex-row">
              <a
                className="rounded-[16px] bg-gray-50 px-4 py-2 text-center font-bold transition-all duration-200 ease-in-out hover:translate-x-1 hover:-translate-y-1 hover:bg-[#FFDD00] hover:drop-shadow-[-2px_2px_0_#aa950b]"
                href="#about"
                onMouseEnter={() => {
                  props.volume ? play() : stop()
                }}
                onMouseLeave={() => stop()}
              >
                About
              </a>
              <a
                className="rounded-[16px] bg-gray-50 px-4 py-2 text-center font-bold transition-all duration-200 ease-in-out hover:translate-x-1 hover:-translate-y-1 hover:bg-[#FFDD00] hover:drop-shadow-[-2px_2px_0_#aa950b]"
                href="#specs"
                onMouseEnter={() => {
                  props.volume ? play() : stop()
                }}
                onMouseLeave={() => stop()}
              >
                Gallery
              </a>
              <a
                className="rounded-[16px] bg-gray-50 px-4 py-2 text-center font-bold transition-all duration-200 ease-in-out hover:translate-x-1 hover:-translate-y-1 hover:bg-[#FFDD00] hover:drop-shadow-[-2px_2px_0_#aa950b]"
                href="#roadmap"
                onMouseEnter={() => {
                  props.volume ? play() : stop()
                }}
                onMouseLeave={() => stop()}
              >
                Roadmap
              </a>
              <a
                className="rounded-[16px] bg-gray-50 px-4 py-2 text-center font-bold transition-all duration-200 ease-in-out hover:translate-x-1 hover:-translate-y-1 hover:bg-[#FFDD00] hover:drop-shadow-[-2px_2px_0_#aa950b]"
                href="#team"
                onMouseEnter={() => {
                  props.volume ? play() : stop()
                }}
                onMouseLeave={() => stop()}
              >
                Team
              </a>
              <Link
                className="rounded-[16px] bg-gray-50 px-4 py-2 text-center font-bold transition-all duration-200 ease-in-out hover:translate-x-1 hover:-translate-y-1 hover:bg-[#FFDD00] hover:drop-shadow-[-2px_2px_0_#aa950b]"
                to="/wheres-jellow"
                onMouseEnter={() => {
                  props.volume ? play() : stop()
                }}
                onMouseLeave={() => stop()}
              >
                Where's Jellow
              </Link>
            </div>
          ) : null}
          <div className="flex flex-row items-center justify-between gap-2">
            <a
              className="hover:box-shadow flex h-8 w-8 items-center justify-center p-1 transition-all ease-in-out hover:-translate-y-1 hover:rotate-12"
              href="http://discord.gg/jellowo"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => {
                props.volume ? play() : stop()
              }}
              onMouseLeave={() => stop()}
            >
              <FontAwesomeIcon icon={faDiscord} className="text-xl" />
            </a>
            <a
              className="hover:box-shadow flex h-8 w-8 items-center justify-center p-1 transition-all ease-in-out hover:-translate-y-1 hover:rotate-12"
              href="https://twitter.com/jellowonft"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => {
                props.volume ? play() : stop()
              }}
              onMouseLeave={() => stop()}
            >
              <FontAwesomeIcon icon={faTwitter} className="text-xl" />
            </a>
            <a
              className="hover:box-shadow flex h-8 w-8 items-center justify-center p-1 transition-all ease-in-out hover:-translate-y-1 hover:rotate-12"
              href="https://www.instagram.com/jellow_o"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => {
                props.volume ? play() : stop()
              }}
              onMouseLeave={() => stop()}
            >
              <FontAwesomeIcon icon={faInstagram} className="text-xl" />
            </a>
            <a
              className="hover:box-shadow flex h-8 w-8 items-center justify-center p-1 transition-all ease-in-out hover:-translate-y-1 hover:rotate-12"
              href="#medium"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => {
                props.volume ? play() : stop()
              }}
              onMouseLeave={() => stop()}
            >
              <FontAwesomeIcon icon={faMedium} className="text-xl" />
            </a>
            <a
              className="hover:box-shadow flex h-8 w-8 items-center justify-center p-1 transition-all ease-in-out hover:-translate-y-1 hover:rotate-12"
              href="#opensea"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => {
                props.volume ? play() : stop()
              }}
              onMouseLeave={() => stop()}
            >
              <img src={opensea} alt="OpenSea" className="h-[20px]" />
            </a>
            <a
              href="#connect"
              className="rounded-[16px] bg-[#FFDD00] py-2 px-4 font-bold transition-all duration-200 ease-in-out hover:translate-x-1 hover:-translate-y-1 hover:drop-shadow-[-2px_2px_0_#aa950b]"
              onMouseEnter={() => {
                props.volume ? play() : stop()
              }}
              onMouseLeave={() => stop()}
            >
              Connect
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
